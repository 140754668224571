import React, { useState } from "react";
import { useParams } from "react-router";
import "../../Pages/Careers.css";
import { Alert } from "bootstrap";

function ApplyForm() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [applyingFor, setApplyingFor] = useState("");
  const [resume, setResume] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const { id } = useParams();
  console.log(id);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("fullName", fullname);
    formData.append("email", email);
    formData.append("mobileNumber", mobileNumber);
    formData.append("applyingFor", applyingFor);
    formData.append("resume", resume);

    fetch("http://localhost:8080/applications", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setShowAlert(true); // Show the alert
        setTimeout(() => {
          setShowAlert(false); // Hide the alert after 3 seconds
        }, 4000);
      })
      .catch((error) => console.error(error));

    setFullname("");
    setApplyingFor("");
    setEmail("");
    setMobileNumber("");
    setResume(null);
  };
  return (
    <>
      {showAlert && (
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <strong>Application submitted successfully!</strong> We will review
          your application and get back to you soon.
        </div>
      )}
      <div className="apply-con">
        <h1>Application Form</h1>
        <hr />
        <form action="" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="">Fullname</label>
            <input
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              type="text"
              placeholder="Enter Fullname"
            />
          </div>
          <div>
            <label htmlFor="">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter Email"
            />
          </div>
          <div>
            <label htmlFor="">Mobile Number</label>
            <input
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              type="text"
              placeholder="Enter Mobilenumber"
            />
          </div>
          <div>
            <label htmlFor="">Applying For</label>
            <input
              value={applyingFor}
              onChange={(e) => setApplyingFor(e.target.value)}
              type="text"
              disabled
            />
          </div>
          <div>
            <label htmlFor="">Upload Resume(pdf)</label>
            <input onChange={(e) => setResume(e.target.files[0])} type="file" />
          </div>
          <button>Apply</button>
        </form>
      </div>
    </>
  );
}

export default ApplyForm;
