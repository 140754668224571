import React from "react";

const ModalComponent = ({ isOpen, onClose, children, applicationId }) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal-overlay "
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: "#fff",
          padding: 20,
          borderRadius: 10,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          width: 500,
          maxWidth: "90%",
        }}
      >
        {children}
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={onClose}
          style={{
            width: 50,
            height: 40,
            borderRadius: 10,
            backgroundColor: "red",
            color: "white",
            position: "absolute",
            top: 7,
            right: 7,
            fontSize: 16,
            cursor: "pointer",
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  );
};

export default ModalComponent;
