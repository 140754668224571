import React, { useEffect } from 'react'
import HeroS from '../Components/Services/HeroS'
import SerDetails from '../Components/Services/SerDetails'
import { useNavigate } from 'react-router-dom';

function Services() {
  const navigate=useNavigate()
  useEffect(() => {
    navigate("/Services/WebDEV");
  }, []);
  return (
    <div>
      <HeroS/>
      <SerDetails/>
    </div>
  )
}

export default Services
