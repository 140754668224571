import React, { useEffect, useState } from "react";
import "./Careers.css";
import Posts from "../Components/Careers/Posts";
import ApplyForm from "../Components/Careers/ApplyForm";
const Careers = () => {
  const [careerPosts, setCareerPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    if (searchKeyword === "") {
      fetchAllCareerPosts();
    }
  }, [searchKeyword]);

  const handleSearch = async () => {
    if (searchKeyword) {
      setLoading(true);
      try {
        const response = await fetch(
          `http://localhost:8080/career/search?keyword=${searchKeyword}`
        );
        const data = await response.json();
        setCareerPosts(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = (e) => {
    setSearchKeyword(e.target.value);
  };
  const fetchAllCareerPosts = async () => {
    setLoading(true);
    try {
      const response = await fetch("http://localhost:8080/career");
      const data = await response.json();
      setCareerPosts(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const data = [
    {
      id: 1,
      name: "fullStack Intern",
      sdtae: "28-7-24",
      edtae: "28-7-24",
      jobDisc: "link",
    },
    {
      id: 2,
      name: "fullStack Intern",
      sdtae: "28-7-24",
      edtae: "28-7-24",
      jobDisc: "link",
    },
    {
      id: 3,
      name: "fullStack Intern fullStack Intern",
      sdtae: "28-7-24",
      edtae: "28-7-24",
      jobDisc: "link",
    },
    {
      id: 4,
      name: "fullStack Intern",
      sdtae: "28-7-24",
      edtae: "28-7-24",
      jobDisc: "link",
    },
    {
      id: 5,
      name: "fullStack Intern",
      sdtae: "28-7-24",
      edtae: "28-7-24",
      jobDisc: "link",
    },
  ];

  return (
    <>
      <div className="CareersCon">
        <div className="careers-left">
          <div>
            <h1></h1>
            <table>
              <thead>
                <th>SNo</th>
                <th>Post name</th>
                <th>Post Date</th>
                <th>End Date</th>
                <th>Job Description</th>
                <th>Action</th>
              </thead>
              <tbody>
                {careerPosts.map((item, i) => {
                  return (
                    <Posts
                      key={i}
                      id={item.id}
                      name={item.postName}
                      sdate={item.startDate}
                      edate={item.endDate}
                      jobDisc={item.jobDescription}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
