import React, { useEffect, useState } from "react";
import "./Dash.css";

import { NavLink, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Dash({ logout }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(false);
    navigate("/DashBoard");
  };
  useEffect(() => {
    navigate("/DashBoard/Poster");
  }, []);

  return (
    <>
      <div className="dash">
        <div className="left-d">
          <div>
            <h1>DashBoard</h1>
            <div className="left-dash">
              <NavLink to="Poster">
                <h3>Poster</h3>
              </NavLink>
              <NavLink to="News">
                <h3>News</h3>
              </NavLink>
              <NavLink to="Career">
                <h3>Career</h3>
              </NavLink>
              <NavLink to="Applied">
                <h3>Applied Students</h3>
              </NavLink>
              <NavLink to="Selected">
                <h3>Selected Students</h3>
              </NavLink>
              <NavLink to="Certificate">
                <h3>Certificate</h3>
              </NavLink>
            </div>
          </div>
          <div>
            <button onClick={handleLogout}>Logout</button>
          </div>
        </div>
        <div className="right-d">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Dash;
