import React, { useState } from "react";

function Addpost() {
  const [postName, setPostName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [jobDescription, setJobDescription] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("postName", postName);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("jobDescription", jobDescription);

    fetch("http://localhost:8080/career", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error));
    alert("Post Added");
  };
  return (
    <>
      <div className="add-post">
        <h1>Add Post</h1>
        <div className="form-add">
          <form action="" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="">Post Name</label>
              <input
                type="text"
                value={postName}
                onChange={(event) => setPostName(event.target.value)}
                placeholder="Enter Post Name"
              />
            </div>
            <div>
              <label htmlFor="">Start Date</label>
              <input
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
                placeholder="Start Date"
              />
            </div>
            <div>
              <label htmlFor="">End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                placeholder="Start Date"
              />
            </div>
            <div>
              <label htmlFor="">Upload JobDiscription</label>
              <input
                type="file"
                onChange={(event) => setJobDescription(event.target.files[0])}
                placeholder="file"
              />
            </div>

            <button type="submit">Add Post</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Addpost;
