import { Modal } from "bootstrap";
import moment from "moment";
import ModelComponent from "./ModalComponent";
import React, { useCallback, useEffect, useState } from "react";

function AppliedStudents() {
  const [applications, setApplications] = useState([]);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [rejectedApplicants, setRejectedApplicants] = useState([]);
  const [interviewLink, setInterviewLink] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [appliedApplicants, setAppliedApplicants] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openApplicationId, setOpenApplicationId] = useState(null);
  const [interviewer, setInterviewer] = useState("");
  const [interviewType, setInterviewType] = useState("");

  const interviewers = [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane " },
    { id: 3, name: "Bob Smith" },
  ];

  useEffect(() => {
    fetch("http://localhost:8080/applications")
      .then((response) => response.json())
      .then((data) => setApplications(data));
    console.log("All ", applications);
  }, []);

  const handleResumeClick = async (id) => {
    try {
      fetch(`http://localhost:8080/applications/resume/${id}`)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.rel = "noopener noreferrer"; // Add this to open in a new tab
          link.click();
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelect = async (id) => {
    if (!interviewer || !interviewType || !interviewLink || !dateTime) {
      alert("Please enter all required fields");
      return;
    }
    console.log("Selecting application with ID:", id);
    try {
      const response = await fetch(
        `http://localhost:8080/applications/select/${id}?interviewer=${interviewer}&interviewType=${interviewType}&interviewLink=${interviewLink}&dateTime=${dateTime}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await response;
      setSelectedApplicants((prevSelectedApplicants) => [
        ...prevSelectedApplicants,
        id,
      ]);
      setApplications(
        applications.filter((application) => application.id !== id)
      );
      console.log("Selected applicants:", selectedApplicants);
      console.log("Applied applicants:", appliedApplicants);
      alert("Email is Sent");
      handleModalClose();
    } catch (error) {
      console.error("Error selecting application:", error);
    }
  };

  const handleReject = async (id) => {
    await fetch(`http://localhost:8080/applications/reject/${id}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response)
      .then(() => {
        setRejectedApplicants([...rejectedApplicants, id]);
      })
      .catch((error) => console.error("Error rejecting application:", error));

    fetch("http://localhost:8080/applications")
      .then((response) => response.json())
      .then((data) => setApplications(data));
    alert("Email is Sent");
  };

  const handleModalOpen = (applicationId) => {
    setIsModalOpen(true);
    setOpenApplicationId(applicationId);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setOpenApplicationId(null);
    setInterviewer(""); // Reset interviewer
    setInterviewType(""); // Reset interview type
    setInterviewLink(""); // Reset interview link
    setDateTime(""); // Reset date and time
  };

  return (
    <>
      <div className="applied-con">
        <h1>Applied Students</h1>
        <div className="Students  ">
          {applications?.map((application) => (
            <div className="st-con" key={application.id}>
              <div className="st-name">
                <h5>{application.fullName}</h5>
                <div className="st-d">
                  <p>
                    Email: <span>{application.email}</span>
                  </p>
                  <p>
                    Phone: <span>{application.mobileNumber}</span>
                  </p>
                  <p>
                    Role: <span>application</span>
                  </p>
                  <p>
                    Applied:{" "}
                    <span>
                      {moment(application?.applyDate).format("MM-DD-YYYY")}
                    </span>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={() => handleResumeClick(application.id)}
                    >
                      Open Resume
                    </a>
                  </p>
                </div>
                <div className="st-s">
                  {isModalOpen ? null : (
                    <div className="h-100 w-100 d-flex align-content-between justify-content-between">
                      <button
                        onClick={() => handleModalOpen(application.id)}
                        className
                      >
                        Schedule
                      </button>
                      <button onClick={() => handleReject(application.id)}>
                        Reject
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <ModelComponent
          isOpen={isModalOpen}
          onClose={handleModalClose}
          applicationId={openApplicationId}
        >
          <h5 className="modal-title text-center">Schedule Interview</h5>
          {/*... */}
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ borderBottom: "1px solid #ddd" }}
            ></div>
            <div className="modal-body" style={{ padding: "20px" }}>
              <label style={{ fontSize: "16px", marginBottom: "10px" }}>
                Interviewer:
              </label>
              <select
                value={interviewer}
                onChange={(e) => setInterviewer(e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                <option value="">Select Interviewer</option>
                {interviewers.map((interviewer) => (
                  <option value={interviewer.name}>{interviewer.name}</option>
                ))}
              </select>
              <br />
              <label style={{ fontSize: "16px", marginBottom: "10px" }}>
                Interview Type:
              </label>
              <select
                value={interviewType}
                onChange={(e) => setInterviewType(e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                <option value="">Select Interview Type</option>
                <option value="HR">HR</option>
                <option value="Technical">Technical</option>
                <option value="Managerial">Managerial</option>
              </select>
              <br />
              <label style={{ fontSize: "16px", marginBottom: "10px" }}>
                Interview Link:
              </label>
              <input
                type="text"
                value={interviewLink}
                onChange={(e) => setInterviewLink(e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
              <br />
              <label style={{ fontSize: "16px", marginBottom: "10px" }}>
                Date and Time:
              </label>
              <input
                type="datetime-local"
                value={dateTime}
                onChange={(e) => setDateTime(e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
              <br />
            </div>
          </div>
          <button
            className="btn btn-primary mt-2"
            style={{
              backgroundColor: "#337ab7",
              color: "#fff",
              padding: "10px 20px",
              fontSize: "16px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => handleSelect(openApplicationId)}
          >
            Schedule
          </button>
        </ModelComponent>
      )}
    </>
  );
}

//

export default AppliedStudents;
