import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
function Posts(props) {
  const handleViewClick = async (id) => {
    try {
      fetch(`http://localhost:8080/career/jd/${id}`)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.rel = "noopener noreferrer"; // Add this to open in a new tab
          link.click();
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <tr>
        <td>{props.id}</td>
        <td>{props.name}</td>
        <td>{moment(props.sdate).format("MM-DD-YYYY")}</td>
        <td>{moment(props.edate).format("MM-DD-YYYY")}</td>
        <td>
          <a href="#" onClick={() => handleViewClick(props.id)}>
            View
          </a>
        </td>
        <td>
          <Link to={`/Apply/${props.name}`}>Apply</Link>
        </td>
      </tr>
    </>
  );
}

export default Posts;
