import React, { useState } from "react";

function News() {
  const [news1, setNews1] = useState("");
  const [news2, setNews2] = useState("");
  const [news3, setNews3] = useState("");

  const submitNews = (e) => {
    e.preventDefault();
    const news = {
      news1,
      news2,
      news3,
    };
    fetch("http://localhost:8080/news", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(news),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error));
  };

  return (
    <div className="dash-news">
      <h1 className="">News</h1>
      <form action="" onSubmit={submitNews}>
        <label className="fs-4">News1</label>
        <input
          type="text"
          value={news1}
          onChange={(e) => setNews1(e.target.value)}
          placeholder="Enter Something..."
        />
        <label className="fs-4">News2</label>
        <input
          type="text"
          value={news2}
          onChange={(e) => setNews2(e.target.value)}
          placeholder="Enter Something..."
        />
        <label className="fs-4">News3</label>
        <input
          type="text"
          value={news3}
          onChange={(e) => setNews3(e.target.value)}
          placeholder="Enter Something..."
        />
        <button className="fs-4 border  " type="submit">
          Add
        </button>
      </form>
    </div>
  );
}

export default News;
