import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Sliders.css";
import "./Responsive.css";
function Menu() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch("http://localhost:8080/news/latest");
        const data = await response.json();
        setNews(data);
      } catch (error) {}
    };
    fetchNews();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <marquee behavior="scroll" direction="left" className="mr-5 ">
          WelCome to Seqtto Software Solutions!
          <span className="px-5 mx-5 ">
            ***
            <span className="px-5 mx-5 news-text">{news?.news1}</span> ***
            <span className="mx-5 px-5 news-text">{news?.news2}</span> ***
            <span className="mx-5 px-5 news-text">{news?.news3}</span> ***
          </span>
        </marquee>
      </div>

      <h2 className="servicesh2">Services</h2>
      <div className="Menu">
        <div className="menuu">
          <h2>Web Development</h2>
          <p>
            Transform your digital presence with professional web development
            services that bring innovation and excellence to your online
            platform.
          </p>
          <button>
            <NavLink to="/services/WebDevelopment">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <h2>Cyber Security</h2>
          <p>
            Guardians of the Digital Realm: Unleashing Unrivaled Cybersecurity
            Services for Unmatched Protection
          </p>
          <button>
            <NavLink to="/services/CyberSecurity">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <h2>UI & UX Design</h2>
          <p>
            Crafting Seamless Digital Experiences: Elevate Your Brand with
            Expert UI/UX Services
          </p>
          <button>
            <NavLink to="/services/WebDevelopment">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <h2>Mobile App Development</h2>
          <p>
            Accomplishing digital transformation through the creation of
            scalable, captivating, and feature-laden mobile applications.
          </p>
          <button>
            <NavLink to="/services/MobileDevelopment">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <h2>Digital Marketing</h2>
          <p>
            Revolutionize Your Online Presence: Unleashing Top-Tier Digital
            Marketing Services for Unrivaled Growth!
          </p>
          <button>
            <NavLink to="/services/DigitalMarketing">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <h2>Testing</h2>
          <p>
            Strategic Testing Solutions: Enriched End-to-End Testing solutions
            for complete Development process
          </p>
          <button>
            <NavLink to="/services/T">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <h2>Backend process optimization</h2>
          <p>
            Strategic Testing Solutions: Enriched End-to-End Testing solutions
            for complete Development process
          </p>
          <button>
            <NavLink to="Services/Bpo">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <h2>Fraud Investigation and Compliances</h2>
          <p>
            Strategic Testing Solutions: Enriched End-to-End Testing solutions
            for complete Development process
          </p>
          <button>
            <NavLink to="Services/FraudInvestigation">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <h2>Staffing</h2>
          <p>
            Strategic Testing Solutions: Enriched End-to-End Testing solutions
            for complete Development process
          </p>
          <button>
            <NavLink to="Services/Staffing">Learn More</NavLink>
          </button>
        </div>
      </div>
      <div className="menu-mobile">
        <div className="menuu">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/code-icon.png"
            alt=""
          />
          <h2>Web Development</h2>
          <button>
            <NavLink to="/services/WebDevelopment">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/internet-network-technology/encrypted-icon.png"
            alt=""
          />
          <h2>Cyber Security</h2>
          <button>
            <NavLink to="/services/CyberSecurity">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/computers-mobile-hardware/desktop-mobile-icon.png"
            alt=""
          />
          <h2>UI & UX Design</h2>
          <button>
            <NavLink to="/services/WebDevelopment">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/mobile-app-dev-icon.png"
            alt=""
          />
          <h2>Mobile App Development</h2>
          <button>
            <NavLink to="/services/MobileDevelopment">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/seo-marketing/website-ads-icon.png"
            alt=""
          />
          <h2>Digital Marketing</h2>
          <button>
            <NavLink to="/services/DigitalMarketing">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/programmer-icon.png"
            alt=""
          />
          <h2>Testing</h2>
          <button>
            <NavLink to="/services/T">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/software-development-icon.png"
            alt=""
          />
          <h2>Backend process optimization</h2>
          <button>
            <NavLink to="Services/Bpo">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/coding-icon.png"
            alt=""
          />
          <h2>Fraud Investigation and Compliances</h2>
          <button>
            <NavLink to="Services/FraudInvestigation">Learn More</NavLink>
          </button>
        </div>
        <div className="menuu">
          <img
            src="https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/web-3-browser-icon.png"
            alt=""
          />
          <h2>Staffing</h2>
          <button>
            <NavLink to="Services/Staffing">Learn More</NavLink>
          </button>
        </div>
      </div>
    </>
  );
}

export default Menu;
