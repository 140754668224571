import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
function Career() {
  const [careers, setCareers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [editedPost, setEditedPost] = useState({});

  useEffect(() => {
    fetch("http://localhost:8080/career", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setCareers(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [editingId]);

  const handleEdit = (id) => {
    setEditingId(id);
    const postToEdit = careers.find((career) => career.id === id);
    setEditedPost(postToEdit);
  };

  const handleEditSubmit = (id, updatedPost) => {
    const formData = new FormData();
    formData.append("postName", updatedPost.postName);
    formData.append("startDate", updatedPost.startDate);
    formData.append("endDate", updatedPost.endDate);
    formData.append("jobDescription", updatedPost.jobDescription);

    fetch(`http://localhost:8080/career/${id}`, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        setCareers(
          careers.map((career) => {
            if (career.id === id) {
              return updatedPost;
            }
            return career;
          })
        );
        setEditingId(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (id) => {
    setCareers(careers.filter((career) => career.id !== id));
    fetch(`http://localhost:8080/career/${id}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then(() => {
        setCareers(careers.filter((career) => career.id !== id));
      })
      .catch((error) => {
        console.error(error);
      });
    console.log("Post Deleted");
  };
  const handleViewClick = async (id) => {
    try {
      fetch(`http://localhost:8080/career/jd/${id}`)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.rel = "noopener noreferrer"; // Add this to open in a new tab
          link.click();
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="career-dash">
        <p>
          If you need to post job or vacancy details, click on the "Add Post"
          button and fill in the required information.
        </p>
        <NavLink to="../Addpost">
          <button className="btn-add">Add Post</button>
        </NavLink>
        <hr />
        <div className="career-posts">
          <h1>Current Careers</h1>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Job Details</th>
                  <th>Job Description</th>
                  <th>Posted Date</th>
                  <th>End Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {careers.map((career) => (
                  <tr key={career.id}>
                    <td>{career.id}</td>
                    <td>{career.postName}</td>
                    <td>
                      <a href="#" onClick={() => handleViewClick(career.id)}>
                        View
                      </a>
                    </td>
                    <td>{moment(career.startDate).format("MM-DD-YYYY")}</td>
                    <td>{moment(career.endDate).format("MM-DD-YYYY")}</td>
                    <td className="but-ac">
                      {editingId === career.id ? (
                        <div
                          className="modal modal-lg fade show"
                          style={{ display: "block" }}
                          tabIndex="-1"
                          role="dialog"
                        >
                          <div className="modal-dialog " role="document">
                            <div className="modal-content ">
                              <div className="modal-header">
                                <h5 className="modal-title">Edit Post</h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => setEditingId(null)}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body w-100  px-5">
                                <EditForm
                                  post={editedPost}
                                  onSubmit={(updatedPost) =>
                                    handleEditSubmit(editingId, updatedPost)
                                  }
                                  onCancel={() => setEditingId(null)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <button
                            className="mt-3"
                            onClick={() => handleEdit(career.id)}
                          >
                            Edit
                          </button>
                          <button
                            className="mt-3"
                            onClick={() => handleDelete(career.id)}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

const EditForm = ({ post, onSubmit, onCancel }) => {
  const [postName, setPostName] = useState(post.postName);
  const [jobDescription, setJobDescription] = useState(post.jobDescription);
  const [startDate, setStartDate] = useState(post.startDate);
  const [endDate, setEndDate] = useState(post.endDate);

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedPost = {
      postName,
      jobDescription,
      startDate,
      endDate,
    };
    onSubmit(updatedPost);
  };

  return (
    <form onSubmit={handleSubmit} className="text-center ">
      <label className="form-control">
        Post Name:
        <input
          className="form-control-lg w-100"
          type="text"
          value={postName}
          onChange={(event) => setPostName(event.target.value)}
        />
      </label>
      <br />
      <label className="form-control">
        Job Description:
        <input
          type="file"
          className="form-control-lg w-100 bg-white border border-2 border-black"
          onChange={(event) => setJobDescription(event.target.files[0])}
        />
      </label>
      <br />
      <label className="form-control">
        Start Date:
        <input
          type="date"
          className="form-control-lg w-100 bg-white border border-2 border-black"
          value={startDate}
          onChange={(event) => setStartDate(event.target.value)}
        />
      </label>
      <br />
      <label className="form-control">
        End Date:
        <input
          type="date"
          className="form-control-lg w-100 bg-white border border-2 border-black"
          value={endDate}
          onChange={(event) => setEndDate(event.target.value)}
        />
      </label>
      <br />
      <div className="but-ac text-center flex-row gap-5 justify-content-center ">
        <button className="px-5 w-100" type="submit">
          Update Post
        </button>
        <button className="px-5 w-100" type="button" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default Career;
