import React, { useEffect, useState } from "react";

function Poster() {
  const [selectedImage, setSelectedImage] = useState(null);

  const [image, setImage] = useState(null);
  // const handle = (event) => {
  //     const file = event.target.files[0];
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //         setSelectedImage(reader.result);
  //     };

  //     if (file) {
  //         reader.readAsDataURL(file);
  //     }
  // };
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setSelectedImage(URL.createObjectURL(file));
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);

    fetch("http://localhost:8080/poster", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        const uploadedFileName = data.split(":")[1].trim();
        setFileName(uploadedFileName);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="pos-dash">
        <form action="">
          <h4>Poster</h4>
          <input type="file" onChange={handleFileChange} />
          <div className="from-dash">
            <label>From:</label>
            <input type="date" onChange={handleStartDateChange} />
          </div>
          <div className="to-dash">
            <label>To:</label>
            <input type="date" onChange={handleEndDateChange} />
          </div>
          <button type="Submit" onClick={handleUpload}>
            Add
          </button>
        </form>
        <div className="preview">
          <h4>Preview</h4>
          <div className="p-dash">
            {selectedImage && <img src={selectedImage} alt="Selected" />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Poster;
