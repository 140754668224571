import moment from "moment";
import React, { useEffect, useState } from "react";
import ModalComponent from "./ModalComponent";

function SelectedStudents() {
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [applications, setApplications] = useState([]);
  const [offerLetterApplicants, setOfferLetterApplicants] = useState([]);
  const [rejectedApplicants, setRejectedApplicants] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openApplicationId, setOpenApplicationId] = useState(null);
  const [stipend, setStipend] = useState("");
  const [reportingTo, setReportingTo] = useState("");
  const [duration, setDuration] = useState("");
  const [position, setPosition] = useState("");
  const [joiningDate, setJoiningDate] = useState("");

  useEffect(() => {
    fetch("http://localhost:8080/applications/selected")
      .then((response) => response.json())
      .then((data) => setApplications(data));
  }, [applications.length]);

  const selectedStudents = applications?.filter((app) =>
    selectedApplicants?.includes(app.id)
  );
  // console.log(selectedStudents);
  const handleResumeClick = async (id) => {
    try {
      fetch(`http://localhost:8080/applications/resume/${id}`)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.rel = "noopener noreferrer"; // Add this to open in a new tab
          link.click();
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = async (id) => {
    const offerLetterDetails = {
      position,
      stipend,
      duration,
      reportingTo,
      joiningDate,
    };

    await fetch(`http://localhost:8080/applications/offer-letter/${id}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(offerLetterDetails),
    })
      .then((response) => response)
      .then(() => setOfferLetterApplicants([...offerLetterApplicants, id]))
      .catch((error) => console.error("Error rejecting application:", error));
    alert("Offer Email is Sent");
    handleModalClose();
  };

  const handleReject = async (id) => {
    await fetch(`http://localhost:8080/applications/reject/${id}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response)
      .then(() => {
        setRejectedApplicants([...rejectedApplicants, id]);
      })
      .catch((error) => console.error("Error rejecting application:", error));

    fetch("http://localhost:8080/applications")
      .then((response) => response.json())
      .then((data) => setApplications(data));
    alert("Email is Sent");
  };

  const handleModalOpen = (applicationId) => {
    setIsModalOpen(true);
    setOpenApplicationId(applicationId);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setOpenApplicationId(null);
    setDuration("");
    setJoiningDate("");
    setPosition("");
    setReportingTo("");
    setStipend("");
  };
  return (
    <>
      <div className="applied-con">
        <h5>Selected List</h5>
        <div className="Students">
          {applications &&
            applications?.map((application) => (
              <div className="st-con" key={application.id}>
                <div className="st-name">
                  <h5>{application.fullName}</h5>
                  <div className="st-d">
                    <p>
                      Email: <span>{application.email}</span>
                    </p>
                    <p>
                      Phone: <span>{application.mobileNumber}</span>
                    </p>
                    <p>
                      Role: <span>application</span>
                    </p>
                    <p>
                      Applied:{" "}
                      <span>
                        {" "}
                        {moment(application?.applyDate).format("MM-DD-YYYY")}
                      </span>
                    </p>
                    <p>
                      <a
                        href="#"
                        onClick={() => handleResumeClick(application.id)}
                      >
                        Open Resume
                      </a>
                    </p>
                  </div>
                  <div className="st-s">
                    {isModalOpen ? null : (
                      <div className="h-100 w-100 d-flex align-content-between justify-content-between">
                        <button onClick={() => handleModalOpen(application.id)}>
                          Offer Letter
                        </button>
                        <button onClick={() => handleReject(application.id)}>
                          Reject
                        </button>
                      </div>
                    )}
                    {isModalOpen && (
                      <ModalComponent
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        applicationId={openApplicationId}
                      >
                        <h5 className="modal-title text-center">
                          Send Offer Letter
                        </h5>
                        {/*... */}
                        {/*... */}
                        <div className="modal-content">
                          <div
                            className="modal-header"
                            style={{
                              borderBottom: "1px solid #ddd",
                            }}
                          ></div>
                          <div
                            className="modal-body"
                            style={{ padding: "20px" }}
                          >
                            <label
                              style={{
                                fontSize: "16px",
                                marginBottom: "10px",
                              }}
                            >
                              Position:
                            </label>
                            <input
                              type="text"
                              value={position}
                              onChange={(e) => setPosition(e.target.value)}
                              style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "16px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />
                            <br />
                            <label
                              style={{
                                fontSize: "16px",
                                marginBottom: "10px",
                              }}
                            >
                              Stipend:
                            </label>
                            <input
                              type="text"
                              value={stipend}
                              onChange={(e) => setStipend(e.target.value)}
                              style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "16px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />
                            <br />
                            <label
                              style={{
                                fontSize: "16px",
                                marginBottom: "10px",
                              }}
                            >
                              Duration:
                            </label>
                            <input
                              type="text"
                              value={duration}
                              onChange={(e) => setDuration(e.target.value)}
                              style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "16px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />
                            <br />
                            <label
                              style={{
                                fontSize: "16px",
                                marginBottom: "10px",
                              }}
                            >
                              Reporting To:
                            </label>
                            <input
                              type="text"
                              value={reportingTo}
                              onChange={(e) => setReportingTo(e.target.value)}
                              style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "16px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />
                            <br />
                            <label
                              style={{
                                fontSize: "16px",
                                marginBottom: "10px",
                              }}
                            >
                              Joining Date:
                            </label>
                            <input
                              type="date"
                              value={joiningDate}
                              onChange={(e) => setJoiningDate(e.target.value)}
                              style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "16px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />

                            <br />
                            <button
                              className="btn btn-primary mt-2"
                              onClick={() => handleSelect(application.id)}
                              style={{
                                width: "100%",
                                backgroundColor: "#337ab7",
                                color: "#fff",
                                padding: "10px 20px",
                                fontSize: "16px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                marginTop: "20px",
                              }}
                            >
                              Send Offer Letter
                            </button>
                          </div>
                          {/*    */}
                          <br />
                        </div>
                      </ModalComponent>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default SelectedStudents;
