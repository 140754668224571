import React, { useEffect, useState } from "react";
import "./Hero.css";
import "./Poster.css";
// import ph from "../Assets/download.jpg";
function Poster() {
  const [open, setOpen] = useState(false);
  const [lastAddedPoster, setLastAddedPoster] = useState(null);
  useEffect(() => {
    fetch("http://localhost:8080/poster/last-added")
      .then((response) => response.json())
      .then((data) => {
        const posterData = data;
        const imageUrl = `http://localhost:8080/poster/${posterData.id}`; // assume image URL is /api/posters/{id}/image
        fetch(imageUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            setLastAddedPoster({ ...posterData, image: url });
          });
      })
      .catch((error) => console.error(error));
    window.addEventListener("load", () => {
      setOpen(true);
    });
  }, []);
  return (
    <>
      <div className={open ? "poster" : "postern"}>
        <a href="https://www.instagram.com/seqtto/">
          <div>
            {lastAddedPoster && (
              <div className="poster-con">
                <img
                  className=""
                  src={lastAddedPoster.image}
                  alt={lastAddedPoster.title}
                />
              </div>
            )}
          </div>
        </a>
        <button onClick={() => setOpen(false)}>X</button>
      </div>
    </>
  );
}

export default Poster;
