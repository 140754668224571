import React, { useEffect, useState } from "react";
import ModalComponent from "./ModalComponent";

const Certificate = () => {
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openApplicationId, setOpenApplicationId] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [certificateSent, setCertificateSent] = useState(false);
  const [uniqueValue, setUniqueValue] = useState("");

  // const handleSendCertificate = async () => {
  //   try {
  //     const response = await fetch(`http://localhost:8080/api/send-certificate/${applicantId}`,{
  //       method:"POST"
  //     });
  //     if (response.data === 'Certificate sent and unique value generated') {
  //       setCertificateSent(true);
  //       setUniqueValue(response.data.split(':')[1].trim());
  //     } else {
  //       console.error('Error sending certificate');
  //     }
  //   } catch (error) {
  //     console.error('Error sending certificate:', error);
  //   }
  // };

  useEffect(() => {
    fetch("http://localhost:8080/applications/selected")
      .then((response) => response.json())
      .then((data) => setSelectedApplicants(data));
  }, []);

  const handleDelete = (id) => {
    const apiUrl = `http://localhost:8080/applications/${id}`;

    fetch(apiUrl, {
      method: "DELETE",
    })
      .then((response) => response)
      .then((data) => {
        setSelectedApplicants((prevApplicants) =>
          prevApplicants.filter((applicant) => applicant.id !== id)
        );
      })
      .catch((error) => console.error(error));
  };

  const handleCertificate = async (id) => {
    const certificateDetails = {
      certificate,
    };

    console.log("Certificate and id ", certificateDetails, id);
    alert("Certificate  is Sent");
    handleModalClose();
  };

  const handleModalOpen = (applicationId) => {
    setIsModalOpen(true);
    setOpenApplicationId(applicationId);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setOpenApplicationId(null);
    setCertificate(null);
  };

  return (
    <>
      <div className="applied-con">
        <h5>Completed Students List</h5>
        <div className="Students">
          {selectedApplicants &&
            selectedApplicants?.map((application) => (
              <div className="st-con" key={application.id}>
                <div className="st-name">
                  <h5>{application.fullName}</h5>
                  <div className="st-d">
                    <p>
                      Email: <span>{application.email}</span>
                    </p>
                    <p>
                      Phone: <span>{application.mobileNumber}</span>
                    </p>
                    <p>
                      Role: <span>application</span>
                    </p>
                  </div>
                  <div className="w-100  gap-2 d-flex justify-content-center align-items-center">
                    <button
                      className="w-100"
                      onClick={() => handleModalOpen(application.id)}
                    >
                      Send Certificate
                    </button>
                    <button onClick={() => handleDelete(application.id)}>
                      Delete
                    </button>
                  </div>

                  {isModalOpen && (
                    <ModalComponent
                      isOpen={isModalOpen}
                      onClose={handleModalClose}
                      applicationId={openApplicationId}
                    >
                      <h5 className="modal-title text-center">
                        Send Certificate
                      </h5>
                      {/*... */}
                      {/*... */}
                      <div className="modal-content ">
                        <div
                          className="modal-header"
                          style={{
                            borderBottom: "1px solid #ddd",
                          }}
                        ></div>
                        <div className="modal-body" style={{ padding: "20px" }}>
                          <label
                            style={{ fontSize: "16px", marginBottom: "10px" }}
                          >
                            Certificate:
                          </label>
                          <input
                            type="file"
                            onChange={(e) => setCertificate(e.target.files[0])}
                            style={{
                              width: "100%",
                              padding: "10px",
                              fontSize: "16px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                            }}
                          />
                          <br />
                          <button
                            className="btn btn-primary mt-3"
                            onClick={() => handleCertificate(application.id)}
                            style={{
                              width: "100%",
                              backgroundColor: "#337ab7",
                              color: "#fff",
                              padding: "10px 20px",
                              fontSize: "16px",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            Send Certificate
                          </button>
                          {certificateSent && (
                            <div>
                              <p>
                                Certificate sent and unique value generated:{" "}
                                {uniqueValue}
                              </p>
                            </div>
                          )}
                        </div>
                        {/*    */}
                        <br />
                      </div>
                    </ModalComponent>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Certificate;
